import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";

import MainLandingPage from "MainLandingPage.js";
import ErrorPage from "ErrorPage.js";
import Content from "Content.js";

import { BrowserRouter, Route, Routes } from "react-router-dom";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Content />}>
          <Route index element={<MainLandingPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}