import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import * as fal from '@fortawesome/pro-duotone-svg-icons';

import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import BackgroundAsImage from "components/hero/BackgroundAsImage";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import ThreePlansWithHalfPrimaryBackground from "components/pricing/ThreePlansWithHalfPrimaryBackground";
import PopularAndRecentBlogPosts from "components/blogs/PopularAndRecentBlogPosts";

import ImageApprentice from 'images/news/apprentice.jpg'
import ImagePackaging from 'images/news/packaging.jpg'
import ImageWeighbridge from 'images/news/weighbridge.jpg'
import ImageIntegrating from 'images/news/integrating.jpg'
import ImageLogin from 'images/blogs/login.png'
import ImageApp from 'images/blogs/app.png'
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";


const HighlightedText = tw.span`text-primary-500`

export default () => {
  return (
    <AnimationRevealPage disabled>

      <BackgroundAsImage
        description="We are working on the future."
        heading="Cloud-based Weighing."
        subheading="Coming Soon."
        buttonText="Contact Us"
        buttonUrl="https://www.weightron.com/contact/"
      />

      <DashedBorderSixFeatures
        heading={<><HighlightedText>System</HighlightedText> Features</>}
        cards={[
          {
            icon: fal.faGlobe,
            title: "Flexible",
            description: "Weightron Cloud offers a single platform for easy-to-use cloud-based weighing from web and mobile, for any industry."
          },
          {
            icon: fal.faCoins,
            title: "Integration",
            description: "We integrate with all major accounts packages such as Sage, SAP and Xero, and offer an API for integration to Weightron Cloud."
          },
          {
            icon: fal.faMicrochip,
            title: "Automation",
            description: "Our system works seemlessly with Weightron's industry leading driver operated terminals for a fully automated weighbridge."
          },
          {
            icon: fal.faArrowsMaximize,
            title: "Scalable",
            description: "Ideal for single or multiple weighbridge applications across any number of sites globally, controllable from one system."
          },
          {
            icon: fal.faPenPaintbrush,
            title: "Customisable",
            description: "Weightron specialise in providing a bespoke experience for your business, we work with you to configure the system to your requirements."
          },
        ]} />

      <FeatureStats
        heading={<>Powering Any Industry</>}
        subheading=""
        description="We pride ourselves in having a solution available for any industry."
        stats={[{
          key: "Products Weighed", value: "2500+",
        },
        {
          key: "Weighings Completed",
          value: "100M+",
        },
        {
          key: "Scales Connected",
          value: "480+",
        },
        ]}
      />

      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
        subheading=""
        description=""
        faqs={[
          {
            question: "When will Weightron Cloud be available?",
            answer: "We are currently working on Weightron Cloud and we are anticipating a fully public release in Q2 2023. If you'd like to get additional information or be notified when Weightron Cloud is available, please contact us at sales@weightroncb.co.uk"
          },
          {
            question: "What does the Weightron Cloud API offer?",
            answer: "The Weightron Cloud API can be configured to offer full integration to all available data in your system. With complete control of what data can be accessed. We also offer WebHooks that can be customised for a range of triggers."
          },
          {
            question: "How customisable is Weightron Cloud?",
            answer: "Our designers work with you to build a bespoke experience from the ground up to perfectly fit your business. We explore every option for data storage, driver data entry and integrations."
          },
        ]}
      />

      <PopularAndRecentBlogPosts
        headingLeft="Recent Updates"
        headingRight="News & Events"
        popularPosts={[
          {
            postImageSrc: ImageLogin,
            title: "Weightron.Cloud OAuth Provider",
            description:
              "Contact us today to be one of the first to utilise our brand new OAuth 2.0 compliant provider as your authentication system of choice.",
            url: "https://weightron.com",
            authorImageSrc: ImageApprentice,
            authorName: "Reece Berry",
            authorProfile: "Weightron App Developer"
          },
          {
            postImageSrc: ImageApp,
            title: "iWM App Launch - Coming Soon",
            description:
              "Sign up for our newsletter to receive updated about the upcoming launch of the iWM app available for integration with all iWM customers.",
            url: "https://weightron.com",
            authorImageSrc: ImageApprentice,
            authorName: "Reece Berry",
            authorProfile: "Weightron App Developer"
          }
        ]}

        recentPosts={[
          {
            postImageSrc: ImageApprentice,
            title: "Software Engineer Reece joins apprenticeship program",
            authorName: "Software Engineer Reece Berry has become...",
            url: "https://www.weightron.com/news/software-engineer-reece-joins-apprenticeship-program/"
          },
          {
            postImageSrc: ImagePackaging,
            title: "Weighing Your Plastic for the Plastic Packaging Tax",
            authorName: "From 1st April 2022, the UK government is...",
            url: "https://www.weightron.com/news/weighing-your-plastic-for-the-plastic-packaging-tax/"
          },
          {
            postImageSrc: ImageWeighbridge,
            title: "Flying the Flag",
            authorName: "Parkerfarm Weighbridge customer shows support...",
            url: "https://www.weightron.com/news/flying-the-flag-parkerfarm-weighbridge-customer-shows-support-for-ukraine/"
          },
          {
            postImageSrc: ImageIntegrating,
            title: "Integrating weighing and Vehicle Management",
            authorName: "Ensuring the smooth running of a cement plant...",
            url: "https://www.weightron.com/news/integrating-weighing-and-vehicle-management/"
          },
        ]}
      />

      <GetStarted
        subheading="Interested in Cloud ?"
        heading="Contact us for more info."
        primaryLinkText="Coming Soon"
        primaryLinkUrl="#"
        secondaryLinkText="Contact Us"
        secondaryLinkUrl="https://www.weightron.com/contact/"
        pushDownFooter={true}
      />

      <MiniCenteredFooter />
    </AnimationRevealPage>
  );
}
