import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import * as fal from '@fortawesome/pro-duotone-svg-icons';

import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import BackgroundAsImage from "components/hero/BackgroundAsImage";
import DashedBorderSixFeatures from "components/features/DashedBorderSixFeatures";
import ThreePlansWithHalfPrimaryBackground from "components/pricing/ThreePlansWithHalfPrimaryBackground";
import PopularAndRecentBlogPosts from "components/blogs/PopularAndRecentBlogPosts";

import ImageApprentice from 'images/news/apprentice.jpg'
import ImagePackaging from 'images/news/packaging.jpg'
import ImageWeighbridge from 'images/news/weighbridge.jpg'
import ImageIntegrating from 'images/news/integrating.jpg'
import ImageLogin from 'images/blogs/login.png'
import ImageApp from 'images/blogs/app.png'
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import { Container, Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HighlightedText = tw.span`text-primary-500`

function getDateXDaysAgo(numOfDays, date = new Date()) {
  const daysAgo = new Date(date.getTime());

  daysAgo.setDate(date.getDate() - numOfDays);

  return daysAgo;
}

export default () => {

  return (
    <AnimationRevealPage disabled>

      <BackgroundAsImage
        mainHeader={false}
        heading="Error 404"
        subheading="Page Not Found"
        statusBox={
          <div></div>
        }
      />

      <MiniCenteredFooter />
    </AnimationRevealPage>
  );
}
